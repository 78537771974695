import React from 'react';

export const DefaultPage = (): JSX.Element => (
  <main style={{ padding: '1rem' }}>
    <p>
      <a href="https://eiendom.no">Eiendom.no</a>
    </p>
    <p>
      <a href="https://newbuilds.com">Newbuilds.com</a>
    </p>
  </main>
);
