import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ConnectedPage, LabelsProvider, CustomLabels } from 'project-news';

import { norwegianLabels } from '../languages';

const APIHost = process.env.REACT_APP_API_HOST || 'https://staging.m2.dev';

export const ConfigurationProvider = (): JSX.Element => {
  const { portal, productId } = useParams();

  const { search } = useLocation();
  const searchParams = React.useMemo(
    () => new URLSearchParams(search),
    [search],
  );
  const postId = searchParams.get('post_id') || '';

  const labels: CustomLabels = portal === 'prosjekter' ? norwegianLabels : {};

  return (
    <ErrorBoundary fallbackRender={() => <div>Something broke</div>}>
      <LabelsProvider {...labels}>
        <ConnectedPage
          host={APIHost}
          productID={productId ?? ''}
          shareURL={`${window.location.origin}/${portal}/${productId}`}
          sharedPostId={postId}
        />
      </LabelsProvider>
    </ErrorBoundary>
  );
};
