import { LabelsContextValue } from 'project-news';

export const norwegianLabels: LabelsContextValue = {
  card: {
    share: 'Del',
  },
  header: {
    ownershipType: 'Eierform',
    ownershipTypes: {
      owned: 'Selveier',
      cooperative: 'Andel',
      stock: 'Aksje',
      other: 'Annen',
    },
    unitType: 'Enhetstype',
    unitTypes: {
      apartment: 'Leilighet',
      detached: 'Enebolig',
      semi_detached: 'Tomannsbolig',
      townhouse: 'Rekkehus',
      other: 'Annet',
      recreational_lot: 'Fritidsboligtomt',
      recreational: 'Fritidsbolig',
      residential_lot: 'Boligtomt',
      unspecified: 'Uspesifisert',
    },
    availableUnits: 'Tilgjengelig',
    bedrooms: 'Soverom',
    and: 'og',
    of: 'av',
    register: 'Registrer interesse',
    visit: 'Besøk prosjektet',
    projectInfo: 'Prosjektinformasjon for {{name}}',
  },
  fullscreen: {
    scrollView: 'Scroll-visning',
    slideshowView: 'Lysbildefremvisning',
  },
  feed: {
    title: 'Nyheter for {{name}}',
    viewAllPosts: 'Se alle oppdateringer',
  },
  share: {
    share: 'Del',
    copyToClipboard: 'Kopiere til utklippstavle',
    copied: 'Kopiert',
  },
  registerInterest: {
    registerInterest: 'Meld interesse',
    name: 'Navn',
    namePlaceholder: 'Skriv ditt navn',
    email: 'E-post',
    emailPlaceholder: 'Skriv din e-post',
    phone: 'Telefon',
    message: 'Melding (valgfri)',
    consent: {
      __html:
        'Ved å registrere deg og melde din interesse vil du bli kontaktet med informasjon om prosjektet. Du aksepterer samtidig Eiendom.no sine <a href="https://eiendom.no/personvern">personvernserklæring</a> og <a href="https://eiendom.no/samtykke">samtykkeerklæring</a>.',
    },
    confirmation: {
      title: 'Takk for interessen',
      text: 'Du vil bli kontaktet med mer informasjon om prosjektet i løpet av kort tid',
      button: 'Tilbake til {{name}}',
    },
  },
};
