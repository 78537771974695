import React from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { ConfigurationProvider } from './components/ConfigurationProvider';
import { DefaultPage } from './components/DefaultPage';

function App() {
  const client = new QueryClient();

  return (
    <QueryClientProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/:portal/:productId"
            element={<ConfigurationProvider />}
          />
          <Route path="*" element={<DefaultPage />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
